import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPaid } from "../../store/slices/paid";
import { Container, Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import { Logout } from "../../utils/helpers/Logout";
import ExportCSVButton from "../../utils/helpers/ExportButton";
import Moment from "moment";

const PaidUsers = () => {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();

  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState();
  const itemsPerPage = 25;
  const totalPages = Math.floor(totaldata / itemsPerPage);
  console.log(totalPages);

  const handlePageChange = (event) => {
    const newPage = event.selected + 1;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  useEffect(() => {
    GetPaid();
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const GetPaid = () => {
    setLoading(true);
    dispatch(getPaid(item))
      .unwrap()
      .then((data) => {
        setData(data?.users?.data);

        setTotaldata(data?.users?.data.totalCount);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {});
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811; Paid Users
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Paid Users</h4>
                </div>
                <div class="d-flex justify-content-end">
                  <ExportCSVButton
                    limit={limit}
                    filename="paidusers.xlsx"
                    getData={getPaid}
                    item={item}
                  />
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : (
                    <div>
                      <table className="table bordered-table sm-table mb-0">
                        <thead>
                          <tr>
                            <th scope="col">
                              <div className="d-flex align-items-center gap-10">
                                S.L
                              </div>
                            </th>

                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile</th>

                            <th scope="col" className="text-center">
                              Date of Registration
                            </th>
                            <th scope="col" className="text-center">
                              Login
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.usersData?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center text-md gap-10">
                                  {index + 1}
                                </div>
                              </td>

                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">
                                    <span className="text-md mb-0 fw-normal text-secondary-light">
                                      <Link
                                        to={`/userDetails/${"paid"}/${
                                          item?._id
                                        }`}
                                      >
                                        {item?.name}
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1 text-md">
                                    {item.email}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1 text-md">
                                    {item.mobileNo}
                                  </div>
                                </div>
                              </td>

                              <td className="text-center">
                                <div className="d-flex align-items-center gap-10 justify-content-center">
                                  {Moment.utc(item.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex align-items-center gap-10 justify-content-center">
                                  {Moment.utc(item.lastLoginTime).format(
                                    "DD-MM-YYYY"
                                  )}
                                  &nbsp; &nbsp;{" "}
                                  {Moment.utc(item.lastLoginTime).format(
                                    "h:mm a"
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div class="mr-5">
                  <div class="mt-2 d-flex justify-content-end align-right">
                    <ReactPaginate
                      previousLabel={"«"}
                      nextLabel={"»"}
                      breakLabel={"..."}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeLinkClassName={"active"}
                      previousClassName={"previous"}
                      nextClassName={"next"}
                      disabledClassName={"disabled"}
                      pageLinkClassName={"page-link"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      breakLinkClassName={"page-link"}
                      forcePage={currentPage - 1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>

      {/* <Modal show={modalShow}>
        <Modal.Header>
          <Modal.Title style={{ marginBottom: "20px", marginTop: "10px" }}>
            Sponsor Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalData && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Name:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.name}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Email:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.email}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Phone Number:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.mobileNo}</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "bold" }}>Sponsor Code:</p>
                <p style={{ fontWeight: "normal" }}>{modalData.sponsorCode}</p>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn saveBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default PaidUsers;
