import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";


class PaidService {
  static getPaid(item) {
    let api = "";
  
      api = `${API_PATHS?.getPaid}?limit=${item?.limit}&page=${item?.page}`;
    
    return axiosInstance.get(api, item);
  }

  static getPaidUserDetails(item) {
    let api = "";
    api = `${API_PATHS.getPaid}?userId=${item.id}`;

    return axiosInstance.get(api, item);
  }


  static getUserCourses(item) {
    let api = "";
    api = `${API_PATHS.usercourses}?userId=${item.userId}`;

    return axiosInstance.get(api, item);
  }
  
  static getSeriesLogs(item) {
    let api = "";
    api = `${API_PATHS.seriestestlogs}?userId=${item.userId}&limit=${item?.limit}&page=${item?.page}`;

    return axiosInstance.get(api, item);
  }


  static getUserStats(item) {
    let api = "";
    
      api = `${API_PATHS.getuserstats}?userId=${item.id}`;
   
    return axiosInstance.get(api);
  }

  static getUnPaid(item) {
    let api = "";
    api = `${API_PATHS.getUnPaid}?userType=${item?.userType}&limit=${item?.limit}&page=${item?.page}&subscriptionStatus=${item?.subscriptionStatus}`;

    return axiosInstance.get(api, item);
  }

  static getActivity(item) {
    let api = "";

    api = `${API_PATHS.getActivity}?limit=${item?.limit}&page=${item?.page}&date=${item.date}`;

    return axiosInstance.get(api, item);
  }


  
  static getPendingPayment(item) {
    let api = "";
    api = `${API_PATHS.getPendingPayment}?limit=${item?.limit}&page=${item?.page}&paymentStatus=${item.paymentStatus}`;

    return axiosInstance.get(api, item);
  }

  // static getActivity(item) {
   
  //     let api = '';
  // api = `${API_PATHS.activitylogs}?limit=${item?.limit}&page=${item?.page}`;
  //       return axiosInstance.get(api, item);
  // }
}
export default PaidService;
