import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Card } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetPaidUserDetails, GetUserStats } from "../../store/slices/paid";
import { Logout } from "../../utils/helpers/Logout";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Moment from "moment";

export const UserDetails = () => {
  const [loading, setLoading] = useState(false);
  const { id, type } = useParams();
  const [details, setDetails] = useState();
  const navigate = useNavigate();
  const [userStats, setUserStats] = useState();
  const [directData, setdirectData] = useState([]);
  const [groupData, setgroupData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserDetailsAndStats = async () => {
      setLoading(true);
      try {
        // Fetch user details
        const userDetailsItem = { id: id };
        const userDetails = await dispatch(
          GetPaidUserDetails(userDetailsItem)
        ).unwrap();
        setDetails(userDetails?.paid?.data?.user);

        // Fetch user stats
        const userStatsItem = { id: id, type: type };
        const userStatsResponse = await dispatch(
          GetUserStats(userStatsItem)
        ).unwrap();
        setUserStats(userStatsResponse?.data?.data);
        setdirectData(userStatsResponse?.data?.data?.directData);
        setgroupData(userStatsResponse?.data?.data?.filteredData);
      } catch ({ message }) {
        alert(message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetailsAndStats();
  }, [dispatch, id, type]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const SeriesTestLogs = () => {
    navigate(`/test-series-logs/${id}`);
  };

  return (
    <div className="page-content">
      <Container fluid={true}>
        <div
          class="text-start my-2 ms-1"
          style={{
            fontWeight: "600",
          }}
        >
          <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
          <Link to="/paidusers">Users</Link>&nbsp;&#8811; User Details
        </div>
        <div class="card">
          <div class="card-header">
            <div class="card-title-wrap bar-success d-flex align-items-center">
              <h4 class="card-title">User Details</h4>
            </div>
            <div className="d-flex justify-content-end p-3">
              <button
                className="btn btn-primary btn-sm ms-2  "
                onClick={SeriesTestLogs}
              >
                SeriesTest Logs
              </button>
            </div>
          </div>

          <Row className="p-3">
            <Col lg={4} md={8} sm={10} className="mb-4">
              <Card
                className="p-3 rounded  "
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <div className="text-center">
                  <img
                    src={
                      details?.profileImageUrl ||
                      "https://hancockogundiyapartners.com/wp-content/uploads/2019/07/dummy-profile-pic-300x300.jpg"
                    }
                    alt="User Profile"
                    className=" mb-3"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h5 className="text-center mb-2">{details?.name}</h5>
                <p className="text-center text-muted mb-2">{details?.email}</p>
                <p className="text-center text-muted">{details?.mobileNo}</p>

                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6 className="mb-0" style={{ color: "#FE7533" }}>
                      Referred By:
                    </h6>
                  </div>
                  <div>
                    <p className="mb-0">{details?.sponsorId?.name}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6 className="mb-0" style={{ color: "#FE7533" }}>
                      Parent:
                    </h6>
                  </div>
                  <div>
                    <p className="mb-0">{details?.parentId?.name}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6 className="mb-0" style={{ color: "#FE7533" }}>
                      No. of Direct Referrals:
                    </h6>
                  </div>
                  <div>
                    <p className="mb-0">{userStats?.totalDirectCount}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6 className="mb-0" style={{ color: "#FE7533" }}>
                      No. of People In Group:
                    </h6>
                  </div>
                  <div>
                    <p className="mb-0">{userStats?.totalGroupCount}</p>
                  </div>
                </div>
              </Card>
            </Col>

            <Col lg={8} md={8} sm={10} className="ps-2">
              <Card
                className="p-3 rounded"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <Tabs
                  defaultActiveKey="direct"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="direct" title="Direct Referrals">
                    <table className="table bordered-table sm-table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="d-flex align-items-center gap-10">
                              S.L
                            </div>
                          </th>

                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>

                          <th scope="col" className="text-center">
                            Date of Registration
                          </th>
                          <th scope="col" className="text-center">
                            Login
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {directData?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center text-md gap-10">
                                {index + 1}
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                  <span className="text-md mb-0 fw-normal text-secondary-light">
                                    <Link
                                      to={`/userDetails/${"paid"}/${item?._id}`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-md">
                                  {item.email}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-md">
                                  {item.mobileNo}
                                </div>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="d-flex align-items-center gap-10 justify-content-center">
                                {Moment.utc(item.created_at).format(
                                  "DD-MM-YYYY"
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="d-flex align-items-center gap-10 justify-content-center">
                                {Moment.utc(item.lastLoginTime).format(
                                  "DD-MM-YYYY"
                                )}
                                &nbsp; &nbsp;{" "}
                                {Moment.utc(item.lastLoginTime).format(
                                  "h:mm a"
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Tab>
                  <Tab eventKey="group" title="Group">
                    <table className="table bordered-table sm-table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="d-flex align-items-center gap-10">
                              S.L
                            </div>
                          </th>

                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>

                          <th scope="col" className="text-center">
                            Date of Registration
                          </th>
                          <th scope="col" className="text-center">
                            Login
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupData?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center text-md gap-10">
                                {index + 1}
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                  <span className="text-md mb-0 fw-normal text-secondary-light">
                                    <Link
                                      to={`/userDetails/${"paid"}/${item?._id}`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-md">
                                  {item.email}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-md">
                                  {item.mobileNo}
                                </div>
                              </div>
                            </td>

                            <td className="text-center">
                              <div className="d-flex align-items-center gap-10 justify-content-center">
                                {Moment.utc(item.created_at).format(
                                  "DD-MM-YYYY"
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="d-flex align-items-center gap-10 justify-content-center">
                                {Moment.utc(item.lastLoginTime).format(
                                  "DD-MM-YYYY"
                                )}
                                &nbsp; &nbsp;{" "}
                                {Moment.utc(item.lastLoginTime).format(
                                  "h:mm a"
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
