import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Table } from "reactstrap";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deletefundamentalUnits,
  getfundamentalUnits,
} from "../../../store/slices/fundamentals";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import ReactPaginate from "react-paginate";
import { MdDelete, MdEdit } from "react-icons/md";

function FundamentalUnits() {
  const { id } = useParams();
  const chapterId = id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [totaldata, setTotaldata] = useState();

  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
    id,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);
  const handleAddfundamentalUnits = () => {
    navigate(`/fundamentals/addunits/${id}`, { state: data[0] });
  };

  useEffect(() => {
    getUnits();
  }, [dispatch, itemsPerPage, currentPage]);

  const getUnits = () => {
    setLoading(true);
    dispatch(getfundamentalUnits(item))
      .unwrap()
      .then((data) => {
        localStorage.setItem(
          "units",
          JSON.stringify(data.fundamentalunits.data[0])
        );
        setData(data.fundamentalunits.data);
        setTotaldata(data?.fundamentalunits?.totalDataCount);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  useEffect(() => {
    localStorage.setItem("fundmentalunitId", id);
  }, []);

  const DeleteUnits = () => {
    dispatch(deletefundamentalUnits(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.fundamentalunits.message);
        setShowModal(false);
        getUnits();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const fundchapterid = localStorage.getItem("fundmentalchapterId");

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <div>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={DeleteUnits}
      />

      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;Fundamental Units
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">
                    {data[0]?.subjectId?.subjectName} &nbsp;&#8811;&nbsp;
                    {data[0]?.chapterId?.chapterName}
                  </h4>
                  &nbsp;
                  <button
                    className="btn  btn-primary btn-sm ms-2 m-1"
                    onClick={handleAddfundamentalUnits}
                  >
                    Add Units
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No Units Added!</h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          <th className="text-center">Unit Number</th>

                          <th className="text-center">Details</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((val, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">
                              {val.unitNumber}
                            </td>

                            <td className="text-center align-middle">
                              <Link
                                to={`/fundamentals/materials/${
                                  val._id ? val._id : null
                                }`}
                              >
                                <button className="btn btn-outline-primary btn-sm ms-2">
                                  Material
                                </button>
                              </Link>

                              <Link
                                to={`/fundamentals/finaltest/${
                                  val._id ? val._id : null
                                }`}
                                style={{ height: "25px", color: "white" }}
                              >
                                <button className="btn btn-outline-primary btn-sm ms-2">
                                  Final Test
                                </button>
                              </Link>
                            </td>

                            <td className="text-center align-middle">
                              <Link
                                to={{
                                  pathname: `/fundamentals/units/edit/${chapterId}/${
                                    val._id ? val._id : null
                                  }`,
                                  state: data[0],
                                }}
                                style={{ height: "25px", color: "white" }}
                              >
                                <button className="btn btn-outline-info btn-sm">
                                  <MdEdit className="viewbtn" />
                                </button>
                              </Link>

                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() => handleDeleteClick(val._id)}
                              >
                                <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>

              <div className="mr-5">
                {totaldata / itemsPerPage > 1 && (
                  <div className="mt-5 d-flex justify-content-end align-right">
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(totaldata / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default FundamentalUnits;
