import React, { useState, useEffect } from "react";
import { Card, Button, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  GetUnitDetails,
  getsingleMaterials,
} from "../../../store/slices/courses";
import { Logout } from "../../../utils/helpers/Logout";

function PreviewMaterial() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { id1, id2 } = useParams();
  let materialsId = id2;

  const [breadData, setBreadData] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetUnitDetails(id1))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };

  React.useEffect(() => {
    const item = {
      id1: id1,
      id2: id2,
    };
    dispatch(getsingleMaterials(item))
      .unwrap()
      .then((data) => {
        setData(data.material.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Check screen size on mount
    handleScreenSizeChange();

    // Listen for screen size changes
    window.addEventListener("resize", handleScreenSizeChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleScreenSizeChange);
    };
  }, []);

  const handleScreenSizeChange = () => {
    // Set the small screen state based on the window width
    setIsSmallScreen(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  const chapterId = localStorage.getItem("chapterId");
  const combinationId = localStorage.getItem("combinationId");
  const unitId = localStorage.getItem("unitId");
  const materialId = localStorage.getItem("materialId");

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>&nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationId}/${chapterId}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${unitId}`}>Units</Link>
              &nbsp;&#8811;&nbsp;{" "}
              <Link to={`/materials/${materialId}`}>Material</Link>
              &nbsp;&#8811;&nbsp; Material Details
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <h4 class="card-title"> Material Details</h4>
                  <div className="d-flex justify-content-end">
                    <Link
                      to={`/editmaterial/${id1}/${id2}`}
                      style={{ height: "25px", color: "white" }}
                    >
                      <button className="btn btn-info">Edit &nbsp;</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {data.map((elem, index) => (
                    <Card key={index} className="mb-3 p-5 mt-3">
                      <div className="d-flex w-100 ">
                        <div style={{ width: isSmallScreen ? "50%" : "30%" }}>
                          <p>Topic Name : </p>
                        </div>
                        <div style={{ width: isSmallScreen ? "50%" : "70%" }}>
                          <p>{elem.materialName}</p>
                        </div>
                      </div>
                      <div className="d-flex w-100 ">
                        <div style={{ width: isSmallScreen ? "50%" : "30%" }}>
                          <p>Topic Number : </p>
                        </div>
                        <div style={{ width: isSmallScreen ? "50%" : "70%" }}>
                          <p>{elem.materialNumber}</p>
                        </div>
                      </div>

                      <div
                        className={`w-100 ${
                          isSmallScreen ? "d-block" : "d-flex"
                        }`}
                      >
                        <div
                          style={{
                            width: isSmallScreen ? "100%" : "30%",
                          }}
                        >
                          <p>Topic Details : </p>
                        </div>
                        <div
                          style={{
                            width: isSmallScreen ? "100%" : "70%",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: elem.description.replace(
                                '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
                                ""
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default PreviewMaterial;
