import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  Editunits,
  GetChapterDetails,
  GetUnitDetails,
} from "../../store/slices/courses";
import { Logout } from "../../utils/helpers/Logout";
import { categorynameregex } from "../../utils/Regex";

const EditUnit = () => {
  const { id1, id } = useParams();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState("idle");
  const dispatch = useDispatch();
  const [unitNumber, setUnitNumber] = useState("");
  const [unitError, setUnitError] = useState();
  const [unitName, setUnitName] = useState("");
  const [unitNameError, setUnitNameError] = useState();
  const [breadData, setBreadData] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
  }, []);

  const fetchData1 = () => {
    dispatch(GetChapterDetails(id))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };

  const save = (e) => {
    e.preventDefault();
    e.preventDefault();
    let isValid = true;
    if (unitName === "") {
      setUnitNameError("Please enter unit name");
      isValid = false;
    } else if (!categorynameregex.test(unitName)) {
      setUnitNameError("Please enter valid name");
      isValid = false;
    }
    if (unitNumber === "") {
      setUnitError("Please enter unit number ");
      isValid = false;
    } else if (unitNumber <= 0) {
      setUnitError("Please enter valid unit number");
      isValid = false;
    }

    if (!isValid) {
      return;
    } else {
      addData(e);
    }
  };

  async function addData(e) {
    e.preventDefault();
    setLoading("loading");

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 4000);

    let item = {
      chapterId: id1,
      unitName,
      id: id,
      unitNumber: parseInt(unitNumber),
      status: 1,
    };
    dispatch(Editunits(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        navigate(`/units/${id1}`);
      })
      .catch(({ message }) => {
        setLoading("idle");
        alert(message);
        setButtonDisabled(false);
      });
  }

  useEffect(() => {
    dispatch(GetUnitDetails(id))
      .unwrap()
      .then((data) => {
        setUnitNumber(data?.user?.data[0]?.unitNumber);
        setUnitName(data?.user?.data[0]?.unitName);
      })
      .catch(({ message }) => {
        setLoading("idle");
        alert(message);
        setButtonDisabled(false);
      });
  }, []);

  const unitChange = (value) => {
    if (parseInt(value) <= 0) {
      setUnitError("Please enter valid unit number");
    } else {
      setUnitError("");
    }
    setUnitNumber(value);
  };

  const unitNameChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'/-]/g, "");
    setUnitName(filteredValue);
    // const categorynameregex = /^[a-zA-Z0-9\s&,'\-\/]*$/;
    if (!categorynameregex.test(filteredValue)) {
      setUnitNameError("Please enter valid Name");
    } else {
      setUnitNameError("");
    }
  };

  const cancelHandler = () => {
    navigate(-1);
  };

  const combinationId = localStorage.getItem("combinationId");
  const chapterId = localStorage.getItem("chapterId");

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationId}/${chapterId}`}>
                {" "}
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${id1}`}>Units</Link> &nbsp;&#8811;&nbsp;Edit
              Unit
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Unit</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Unit Name</span>
                      <input
                        type="text"
                        placeholder="Enter unit name"
                        className="form-control"
                        value={unitName}
                        onChange={(e) => unitNameChange(e.target.value)}
                      />
                      <p className="alert-message">{unitNameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Unit Number</span>
                      <input
                        type="string"
                        placeholder="Enter unit number"
                        className="form-control"
                        value={unitNumber}
                        maxLength={3}
                        onInput={(e) => {
                          e.target.value = e?.target?.value
                            ?.replace(/[^0-9]/g, "")
                            .slice(0, 3);
                        }}
                        onChange={(e) => unitChange(e.target.value)}
                      />
                      <p className="alert-message">{unitError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100 "
                      onClick={cancelHandler}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};
export default EditUnit;
