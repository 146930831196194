import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class CurrentAffairService {
  static postCurrentAffairs(item) {
    return axiosInstance.post(API_PATHS.postcurrentaffais, item);
  }
  static getCurrentAffairs(id) {
    let api;
    let year = new Date().getFullYear();
    if (id) {
      api = `${API_PATHS.getCurrentAffairs}?id=${id}&year=${year}`;
    } else {
      api = `${API_PATHS.getCurrentAffairs}`;
    }

    return axiosInstance.get(api);
  }
  static editCurrentAffairs(item) {
    return axiosInstance.put(API_PATHS.editCurrentAffairs, item);
  }

  static deleteCurrentAffairs(id) {
    return axiosInstance.delete(`${API_PATHS.deletcurrentAffairs}?id=${id}`);
  }
}

export default CurrentAffairService;
